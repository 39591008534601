import Vue from "vue";
import App from "./App.vue";
import "./registerServiceWorker";
import router from "./router";
import "./assets/tailwind.scss";

import VueGtag from "vue-gtag";

import typeHeader from "@/components/type/header/index.vue";
import calendar from "@/components/calendar";
Vue.component("typeHeader", typeHeader);
Vue.component("calendar", calendar);

Vue.config.productionTip = false;

Vue.use(
  VueGtag,
  {
    config: {
      // Pigeon Forge
      id: "G-DHNVLRZT6N"
    },
    includes: [
      {
        // Branson
        id: "G-R0NK1RR5PK"
      },
      {
        // Myrtle Beach
        id: "G-B26Z0DQD44"
      },
      {
        // Orlando
        id: "G-D2LSC051RX"
      },
      {
        // South Florida
        id: "G-HWJPW345YD"
      },
      {
        // Generic
        id: "G-LS2MYGKC25"
      }
    ]
  },
  router
);

new Vue({
  router,
  render: h => h(App)
}).$mount("#app");
