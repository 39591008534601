<template lang="pug">
.calendar
  .cal-grid
    section.month#month(v-for='month in months' :key="month.index" :ref="months" :class="{ 'has-winner' : month.winner }")
      figure(v-if="month.winner" v-html="require(`!html-loader!@/assets/img/icons/medal.svg`)")
      .content
        h5.month(v-html="month.month")
        section.winner(v-if="month.winner") 
          p(v-html="'🎉 Congratulations <em>' + month.winner.name + '</em>'")
          p(v-html="'of <em>' + month.winner.location + '</em>!'")
</template>

<script>
export default {
  name: "Calendar",
  props: {
    months: Array
  },
  data: function() {
    return {};
  }
};
</script>

<style scoped lang="scss">
@import "../../assets/scss/color/_mixin";

@mixin aspect-ratio($width, $height) {
  @apply relative;
  &:before {
    @apply block w-full;
    content: "";
    padding-top: ($height / $width) * 100%;
  }
  > .content {
    @apply absolute inset-0;
    .month {
      @apply uppercase;
      color: alpha(var(--color-shadow), 0.25);
    }
  }
}

.cal-grid {
  @apply grid w-full;
  grid-gap: calc(var(--fs-gap) * 2);

  // Mobile
  @screen maxSm {
    @apply grid-cols-1;
  }
  // Desktop
  @screen minMd {
    @apply grid-cols-3;
  }

  > section {
    @apply overflow-hidden col-span-1 p-6;
    //@include aspect-ratio(1.75, 1);
    @include aspect-ratio(2, 1);

    background-color: alpha(var(--color-white), 1);
    box-shadow: var(--bs) alpha(var(--color-shadow), 0.05);
    border-radius: var(--fi-radius);

    @for $i from 1 through 12 {
      &:nth-child(#{$i}) {
        .month {
          //background-size: calc(#{$i} * 100);
        }
      }
    }

    ::v-deep svg {
      @apply absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -65%);
      height: 100%;
      fill: alpha(var(--color-shadow), 0.05);
    }

    &:not(.has-winner) {
      // If there is no winner, yet
    }
    &.has-winner {
      // If there is a winner
      background: linear-gradient(
          127deg,
          hsla(
            var(--color-s-indigo-h),
            var(--color-s-indigo-s),
            var(--color-s-indigo-l),
            1
          ),
          hsla(
              var(--color-s-pink-h),
              var(--color-s-pink-s),
              var(--color-s-pink-l),
              0.05
            )
            70.71%
        ),
        linear-gradient(
          218deg,
          hsla(
            var(--color-s-blue-h),
            var(--color-s-blue-s),
            var(--color-s-blue-l),
            1
          ),
          hsla(
              var(--color-s-indigo-h),
              var(--color-s-indigo-s),
              var(--color-s-indigo-l),
              0.125
            )
            70.71%
        );
      h5,
      p {
        color: alpha(var(--color-white), 1) !important;
      }
    }

    .content {
      padding: 1rem;
      section {
        &.winner {
          @apply mt-3;
          p {
            @apply m-0;
            ::v-deep em {
              @apply not-italic font-bold;
            }
          }
        }
      }
    }
  }
}
</style>
