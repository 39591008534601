<template lang="pug">
.countdown(v-show ="statusType !== 'expired'")
  section
    p(v-html ="days")
    p Days
  section
    p(v-html="hours")
    p Hours
  section
    p(v-html="minutes")
    p Minutes
  section
    p(v-html="seconds")
    p Seconds
</template>

<script>
import dayjs from "dayjs";

export default {
  name: "Countdown",
  props: [],
  data: function() {
    return {
      starttime: dayjs()
        .startOf("day")
        .format("MMM D, YYYY HH:MM:ss"),
      endtime: dayjs()
        .set("date", 1)
        .endOf("day")
        .add(1, "month")
        .subtract(1, "day")
        .format("MMM D, YYYY HH:MM:ss"),
      start: "",
      end: "",
      interval: "",
      days: "",
      minutes: "",
      hours: "",
      seconds: "",
      //message: "",
      statusType: ""
      //statusText: "",
    };
  },
  created: function() {
    //this.wordString = JSON.parse(this.trans);
  },
  mounted() {
    this.start = new Date(this.starttime).getTime();
    this.end = new Date(this.endtime).getTime();
    // Update the count down every 1 second
    this.timerCount(this.start, this.end);
    this.interval = setInterval(() => {
      this.timerCount(this.start, this.end);
    }, 1000);
  },
  methods: {
    timerCount: function(start, end) {
      // Get todays date and time
      var now = new Date().getTime();

      // Find the distance between now an the count down date
      var distance = start - now;
      var passTime = end - now;

      if (distance < 0 && passTime < 0) {
        //this.message = this.wordString.expired;
        this.statusType = "expired";
        //this.statusText = this.wordString.status.expired;
        clearInterval(this.interval);
        return;
      } else if (distance < 0 && passTime > 0) {
        this.calcTime(passTime);
        //this.message = this.wordString.running;
        this.statusType = "running";
        //this.statusText = this.wordString.status.running;
      } else if (distance > 0 && passTime > 0) {
        this.calcTime(distance);
        //this.message = this.wordString.upcoming;
        this.statusType = "upcoming";
        //this.statusText = this.wordString.status.upcoming;
      }
    },
    calcTime: function(dist) {
      // Time calculations for days, hours, minutes and seconds
      this.days = Math.floor(dist / (1000 * 60 * 60 * 24));
      this.hours = Math.floor(
        (dist % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
      );
      this.minutes = Math.floor((dist % (1000 * 60 * 60)) / (1000 * 60));
      this.seconds = Math.floor((dist % (1000 * 60)) / 1000);
    }
  }
};
</script>

<style scoped lang="scss">
.countdown {
  @apply grid grid-cols-4;
  grid-gap: calc(var(--fs-gap) * 0.75);
  > section {
    @apply col-span-1 text-center;
    p {
      @apply m-0 uppercase tracking-widest;
      font-family: var(--ff-header);

      &:nth-of-type(2) {
        font-size: var(--fs-xxs);
        margin-top: -5px;
      }
    }
  }
}
</style>
