<template lang="pug">
#app
  //notificationBar(:currentMonth="currentMonth")
  sweepHeader
  transition(name='fade' mode="out-in")
    router-view(:currentYear="currentYear" :currentMonth="currentMonth" :months="months" :campaign="campaign")
  sweepFooter
</template>

<script>
import dayjs from "dayjs";
import sweepHeader from "@/views/element/header/index.vue";
import sweepFooter from "@/views/element/footer/index.vue";
import notificationBar from "@/components/cta/index.vue";

// Campaign Details
var dflt = {
  id: "Generic Landing Page",
  headline: "Win a vacation!",
  tagline: "The United States",
  url: "https://mygiveaway.capital.promo",
  video: {
    mp4:
      "https://player.vimeo.com/external/506275469.hd.mp4?s=731c02ade81c8909e052337addbccdd949454013&profile_id=174",
    still: "https://i.vimeocdn.com/video/1047625533.jpg"
  },
  address: {
    city: ""
  }
};
var branson = {
  id: "Branson, MO",
  headline: "Adventure in the Ozarks",
  tagline: "The Ozarks",
  url: "mybransongiveaway.com",
  video: {
    mp4:
      "https://player.vimeo.com/external/506228620.hd.mp4?s=a9070767293f641416d3bc481ba58936ec3c87cb&profile_id=174",
    still: "https://i.vimeocdn.com/video/1047535604.jpg"
  },
  address: {
    city: "Branson",
    state: "Missouri"
  }
};
var pigeon = {
  id: "Pigeon Forge, TN",
  headline: "Explore Mountainside Adventure",
  tagline: "Smoky Mountains",
  url: "mypigeonforgegiveaway.com",
  video: {
    mp4:
      "https://player.vimeo.com/external/506275256.hd.mp4?s=c4e90850fd0c66dce49a1ea2c024b53020ad7d71&profile_id=174",
    still: "https://i.vimeocdn.com/video/1047668856.jpg"
  },
  address: {
    city: "Pigeon Forge",
    state: "Tennessee"
  }
};
var orlando = {
  id: "Orlando, FL",
  headline: "Visit the Destination Capitol",
  tagline: "Florida",
  url: "myorlandogiveaway.com",
  video: {
    mp4:
      "https://player.vimeo.com/external/506274740.hd.mp4?s=92c450b038b953c1d4ea1ebc8120314af63c11ac&profile_id=174",
    still: "https://i.vimeocdn.com/video/1047623999.jpg"
  },
  address: {
    city: "Orlando",
    state: "Florida"
  }
};
var myrtle = {
  id: "Myrtle Beach, SC",
  headline: "Swim in the Atlantic",
  tagline: "The Beach",
  url: "mymyrtlebeachgiveaway.com",
  video: {
    mp4:
      "https://player.vimeo.com/external/506228704.hd.mp4?s=d461715426f8ab982d59d4134caafe62687f3357&profile_id=174",
    still: "https://i.vimeocdn.com/video/1047550694.jpg"
  },
  address: {
    city: "Myrtle Beach",
    state: "South Carolina"
  }
};
var sFlorida = {
  id: "Hollywood, FL",
  headline: "Find your Beach Paradise",
  tagline: "Southern Seas",
  url: "pigeonforgegiveaway.com",
  video: {
    mp4:
      "https://player.vimeo.com/external/506275009.hd.mp4?s=3eeda3a0f24430af7ba7c21cac65fcf102262e01&profile_id=174",
    still: "https://i.vimeocdn.com/video/1047624496.jpg"
  },
  address: {
    city: "South",
    state: "Florida"
  }
};

export default {
  name: "Sweepstakes",
  metaInfo() {
    return {
      title: "Enter",
      titleTemplate: "%s | My " + this.campaign.address.city + " Giveaway",
      meta: [
        {
          vmid: "description",
          name: "description",
          content:
            "hello world, this is an example of adding a description with vueMeta"
        },
        { name: "robots", content: "index,follow" }
      ]
    };
  },
  components: {
    sweepHeader,
    sweepFooter,
    notificationBar
  },
  data: function() {
    return {
      currentYear: new Date().getFullYear(),
      currentMonth: dayjs().format("MMMM"),
      campaign: Array,
      months: [
        // {
        //   month: "January",
        //   dates: {
        //     begin: dayjs()
        //       .set("date", 1)
        //       .month(0)
        //       .add(1, "month")
        //       .subtract(1, "day")
        //       .format("MMMM DD, YYYY"),
        //     end: dayjs()
        //       .set("date", 14)
        //       .month(0)
        //       .add(1, "month")
        //       .format("MMMM DD, YYYY")
        //   },
        //   winner: false
        // },
        // {
        //   month: "February",
        //   dates: {
        //     begin: dayjs()
        //       .set("date", 1)
        //       .month(1)
        //       .add(1, "month")
        //       .subtract(1, "day")
        //       .format("MMMM DD, YYYY"),
        //     end: dayjs()
        //       .set("date", 14)
        //       .month(1)
        //       .add(1, "month")
        //       .format("MMMM DD, YYYY")
        //   },
        //   winner: false
        // },
        // {
        //   month: "March",
        //   dates: {
        //     begin: dayjs()
        //       .set("date", 1)
        //       .month(2)
        //       .add(1, "month")
        //       .subtract(1, "day")
        //       .format("MMMM DD, YYYY"),
        //     end: dayjs()
        //       .set("date", 14)
        //       .month(2)
        //       .add(1, "month")
        //       .format("MMMM DD, YYYY")
        //   },
        //   winner: false
        //   //winner: {
        //   //name: null,
        //   //location: null,
        //   //},
        // },
        // {
        //   month: "April",
        //   dates: {
        //     begin: dayjs()
        //       .set("date", 1)
        //       .month(3)
        //       .add(1, "month")
        //       .subtract(1, "day")
        //       .format("MMMM DD, YYYY"),
        //     end: dayjs()
        //       .set("date", 14)
        //       .month(3)
        //       .add(1, "month")
        //       .format("MMMM DD, YYYY")
        //   }
        // },
        {
          month: "May",
          dates: {
            begin: dayjs()
              .set("date", 1)
              .month(4)
              .add(1, "month")
              .subtract(1, "day")
              .format("MMMM DD, YYYY"),
            end: dayjs()
              .set("date", 14)
              .month(4)
              .add(1, "month")
              .format("MMMM DD, YYYY")
          },
          winner: {
            name: "Quinn H.",
            location: "Nephi, UT"
          }
        },
        {
          month: "June",
          dates: {
            begin: dayjs()
              .set("date", 1)
              .month(5)
              .add(1, "month")
              .subtract(1, "day")
              .format("MMMM DD, YYYY"),
            end: dayjs()
              .set("date", 14)
              .month(5)
              .add(1, "month")
              .format("MMMM DD, YYYY")
          },
          winner: {
            name: "Laura A.",
            location: "Darden, TN"
          }
        },
        {
          month: "July",
          dates: {
            begin: dayjs()
              .set("date", 1)
              .month(6)
              .add(1, "month")
              .subtract(1, "day")
              .format("MMMM DD, YYYY"),
            end: dayjs()
              .set("date", 14)
              .month(6)
              .add(1, "month")
              .format("MMMM DD, YYYY")
          },
          winner: {
            name: "Catherine G.",
            location: "Blaine, MN"
          }
        },
        {
          month: "August",
          dates: {
            begin: dayjs()
              .set("date", 1)
              .month(7)
              .add(1, "month")
              .subtract(1, "day")
              .format("MMMM DD, YYYY"),
            end: dayjs()
              .set("date", 14)
              .month(7)
              .add(1, "month")
              .format("MMMM DD, YYYY")
          },
          winner: {
            name: "Gloria F.",
            location: "Henderson, TX"
          }
        },
        {
          month: "September",
          dates: {
            begin: dayjs()
              .set("date", 1)
              .month(8)
              .add(1, "month")
              .subtract(1, "day")
              .format("MMMM DD, YYYY"),
            end: dayjs()
              .set("date", 14)
              .month(8)
              .add(1, "month")
              .format("MMMM DD, YYYY")
          },
          winner: {
            name: "Pat B.",
            location: "Nixa, MO"
          }
        },
        {
          month: "October",
          dates: {
            begin: dayjs()
              .set("date", 1)
              .month(9)
              .add(1, "month")
              .subtract(1, "day")
              .format("MMMM DD, YYYY"),
            end: dayjs()
              .set("date", 14)
              .month(9)
              .add(1, "month")
              .format("MMMM DD, YYYY")
          },
          winner: {
            name: "J.D. C.",
            location: "Glenmora, LA"
          }
        },
        {
          month: "November",
          dates: {
            begin: dayjs()
              .set("date", 1)
              .month(10)
              .add(1, "month")
              .subtract(1, "day")
              .format("MMMM DD, YYYY"),
            end: dayjs()
              .set("date", 14)
              .month(10)
              .add(1, "month")
              .format("MMMM DD, YYYY")
          },
          winner: {
            name: "Ashley W.",
            location: "Tulsa, OK"
          }
        },
        {
          month: "December",
          dates: {
            begin: dayjs()
              .set("date", 1)
              .month(11)
              .add(1, "month")
              .subtract(1, "day")
              .format("MMMM DD, YYYY"),
            end: dayjs()
              .set("date", 14)
              .month(11)
              .add(1, "month")
              .format("MMMM DD, YYYY")
          },
          winner: {
            name: "Michael T.",
            location: "Des Moines, WA"
          }
        }
      ]
    };
  },
  methods: {
    campaignLocator() {
      if (
        this.$route.query.location === "branson" ||
        window.location.host === "mybransongiveaway.com"
      ) {
        this.campaign = branson;
        return;
      } else if (window.location.host === "mypigeonforgegiveaway.com") {
        console.log("It selected it");
        this.campaign = pigeon;
        return;
      } else if (window.location.host === "myorlandogiveaway.com") {
        this.campaign = orlando;
        return;
      } else if (window.location.host === "mymyrtlebeachgiveaway.com") {
        this.campaign = myrtle;
        return;
      } else if (window.location.host === "mysouthfloridagiveaway.com") {
        this.campaign = sFlorida;
        return;
      } else {
        this.campaign = dflt;
        return;
      }
    }
  },
  created() {
    this.campaignLocator();
  }
};
</script>

<style lang="scss">
@import "assets/scss/color/_mixin";

#app {
  background-color: alpha(var(--color-gray), 1);
}

/* Enter and leave animations can use different */
/* durations and timing functions.              */
.fade-enter-active,
.fade-leave-active {
  transition: all 0.45s ease;
}
.fade-enter, .fade-leave-to
/* .slide-fade-leave-active below version 2.1.8 */ {
  @apply opacity-0;
}

/* Enter and leave animations can use different */
/* durations and timing functions.              */
.slide-down-enter-active,
.slide-down-leave-active {
  transition: all 0.45s var(--ea);
}
.slide-down-enter, .slide-down-leave-to
/* .slide-fade-leave-active below version 2.1.8 */ {
  @apply opacity-0;
  transform: translateY(100px);
}
</style>
