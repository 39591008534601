<template lang="pug">
.participation.container
  section
    h2 Details of Participation
    h6 Consumer Disclosure: No purchase necessary. A purchase will not increase your chances of winning.
  p 
    span Sponsor: 
    | Capital Vacations (“Sponsor”), 3179 North Gretna Road, Branson, Missouri 65616.
  p
    span Entering: 
    | 12 Months, 12 Getaways begins January 1, {{ currentYear }}, and ends December 31, {{ currentYear }}. To enter, eligible participants must submit an official entry form with completed contact information at Sponsor-selected events or online at {{ campaign.url }}. Eight (8) drawings will be completed at the sponsor's corporate address as follows:
  .table.deadlines
    span.head Entries Received By
    span.head Winner Announced By
    span.set(v-for="month in months")
      span(v-html="month.dates.begin")
      span(v-html="month.dates.end")
  p
    span Eligibility: 
    | Open to all US residents 25 years or older. Entries must be complete to be eligible. Void where prohibited by law and where state licensing and/or registration requirements have not been met. Capital Vacations employees and their immediate family members are not eligible to participate. Limit one entry per person, regardless of entry method.
  p
    span Grand Prize: 
    | One (1) winner for each of the 8 drawings will receive $250.00 spending money and a 3-day/2-consecutive night's Vacation Certificate in a preferred hotel at participating properties, for up to two adults and/or two adults and two children. Approximate retail value is $500.00 per drawing. Cash Prize is payable by check. Prize is non-transferable. Grand prize is subject to availability and subject to change at the Sponsor’s discretion. Travel costs and incidentals are the responsibility of winner. All amounts are represented in US dollars unless otherwise stated.
  p
    span Winning: 
    | One (1) winner will be selected by random drawing from all eligible entries received in the drawing period by Sponsor at the Sponsor’s corporate address above, if delayed by weather the next day sponsor is open. Winners need not to be present to win. Official notice to the winner will be sent by traceable method of delivery. The selected prize must be claimed within thirty (30) days of notification. If the prize is not claimed within thirty (30) days after receipt of notice, the prize will remain unawarded. Winner is responsible for any income tax or tax withholding resulting from prize. Participants selected as prize winners consent to use of their names, city, and state of residency for advertising, publicity, and promotion without additional compensation or permission and may also be required to sign an affidavit and release to receive their prize.
  p
    span Winner List: 
    | The winner list is shown below. The winner list will be posted after winner confirmation is complete.
  .table.winners
    span.head Month
    span.head Winner
    span.head Prize Description
    span.set(v-for="month in months" v-if="month.winner" :class="month.month.toLowerCase()")
      span(v-html="month.month")
      span(v-html="month.winner.name") 
      //span(v-html="month.winner.name.replace(/ .*/, ' ')")
      span Three-day, two-night vacation
  p 
    span Odds of Winning: 
    | Odds of winning the prize depend upon the number of eligible entries received.
  p 
    span General Rules: 
    | Sponsors reserve the right at their sole discretion to modify, terminate or suspend all or a portion of this Sweepstakes should non-authorized human interference, fraud, printing errors, internet viruses, hackers, or other causes beyond the control of the Sponsors corrupts or impairs administration, security, fairness, or proper operation of this Sweepstakes. Sponsors assume no responsibility for lost, late, or illegible entries. Sponsors are not liable for injury, loss, or damage of any kind resulting from acceptance or use of the prizes or participation in this Sweepstakes. This form and the entries made are the exclusive property of Capital Vacations and any use by unauthorized parties without the express consent of the sponsor is prohibited. Entrants agree to be contacted by telephone (including wireless numbers if provided), email, or regular mail for the purpose of promoting the sales of timeshare intervals and/or vacation packages. Entrant acknowledges that participation in this promotion supersedes any in-house, state, or federal no-call list.
  p 
    span Countdown Timer: 
    | The website may feature a countdown timer is used for general display purposes only. The counter should not be used as the actual time remaining in the drawing. Please refer to the current date and time at your location during time of entry. This form will close on the expiration date listed.
</template>

<script>
export default {
  name: "Legal",
  metaInfo: {
    title: "Details of Participation"
  },
  props: {
    currentYear: Number,
    months: Array,
    campaign: Object
  },
  data: function() {
    return {};
  },
  components: {}
};
</script>

<style scoped lang="scss">
@import "../assets/scss/color/_mixin";

.participation {
  width: var(--ms-w-legal);
  section {
    @apply border-b pb-8 mb-8;
  }
  p {
    @apply text-justify;
    > span {
      @apply uppercase font-bold;
    }
  }
}
.table {
  @apply grid w-full;
  grid-gap: 0 var(--fs-gap);
  margin: calc(var(--fl-base) * 2) auto;

  &.deadlines {
    @apply grid-cols-2;
    > span.set {
      @apply col-span-2 grid grid-cols-2;
    }
  }
  &.winners {
    @apply grid-cols-4;
    > span {
      &.head,
      &.set {
      }
      &.head {
        &:nth-child(3) {
          @apply col-span-2;
        }
      }
      &.set {
        @apply col-span-4 grid grid-cols-4;
        span {
          &:nth-child(3) {
            @apply col-span-2;
          }
        }
      }
    }
  }

  > span {
    @mixin pad {
      @apply py-3 px-6;
    }
    &.head {
      @apply font-bold;
      @include pad;
    }
    &.set {
      &:nth-child(odd) {
        background-color: alpha(var(--color-black-coral), 0.1);
      }
      > span {
        @apply col-span-1;
        @include pad;
      }
    }
  }
}
</style>
