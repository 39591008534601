<template lang="pug">
section
  p(v-html="text")
  p(v-if="subtitle" v-html="subtitle")
</template>

<script>
export default {
  name: "Header",
  data: function() {
    return {};
  },
  props: {
    text: String,
    subtitle: String
  }
};
</script>

<style scoped lang="scss">
@import "../../../assets/scss/color/_mixin";

section {
  @apply relative uppercase text-center;
  $margin: 125px;
  margin: calc(#{$margin} * 0.45) 0 $margin;
  p {
    &:nth-child(1) {
      @apply relative tracking-widest z-10;
      font-size: var(--fs-xl);
      font-weight: var(--fw-bold);
      //color: alpha(var(--color-s-indigo), 1);
      @include gradient-a-text;
    }
    &:nth-child(2) {
      @apply absolute select-none pointer-events-none leading-normal overflow-hidden;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -55%);
      font-size: calc(var(--fs-xl) * 4);
      font-weight: var(--fw-bold);
      color: alpha(var(--color-shadow), 0.045);

      // This stops the overlow scrolling on mobile if the sub text span exceeds 100vw
      max-width: 100vw;
    }
  }
}
</style>
