import Vue from "vue";
import VueRouter from "vue-router";
import VueMeta from "vue-meta";

import Home from "../views/Home.vue";
import Legal from "../views/Legal.vue";
import FourOhFour from "../views/error/404.vue";

import VueConfetti from "vue-confetti";

Vue.use(VueRouter);
Vue.use(VueMeta);
Vue.use(VueConfetti);

const routes = [
  {
    path: "/",
    name: "Home",
    component: Home
  },
  {
    path: "/legal",
    name: "Legal",
    component: Legal
  },
  {
    path: "/winners",
    name: "Winners",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/Winners.vue")
  },
  {
    path: "*",
    name: "404",
    component: FourOhFour
  }
];

const router = new VueRouter({
  mode: "history",
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition;
    } else {
      return { x: 0, y: 0 };
    }
  }
});

export default router;
