<template lang="pug">
.perk
  figure(v-if="ico" v-html="require(`!html-loader!@/assets/img/icons/${ico}`)")
  h6(v-html="title")
  p(v-html="copy")
</template>

<script>
export default {
  name: "Perk",
  props: {
    ico: String,
    title: String,
    copy: String
  }
};
</script>

<style scoped lang="scss">
@import "../../assets/scss/color/_mixin";

.perk {
  $figure-size: 80px;
  $figure-bg: var(--color-purple);

  &:hover {
    figure {
      &:after {
        //transform: scale(1.25);
      }
    }
  }

  figure {
    @apply relative rounded-full mx-auto flex items-center;
    height: $figure-size;
    width: $figure-size;
    margin-bottom: var(--fs-gap);
    box-shadow: var(--bs) alpha(var(--color-shadow), 0.05);

    // This controls the color of color of the SVG
    color: lightness($figure-bg, 2);

    // Cool Stuff
    @include gradient-a;

    &:after {
      //@apply absolute rounded-full inset-0 w-full h-full;
      //z-index: -1;
      //content: "";
      //background-color: lightness($figure-bg, 1.5);
      //transition: all 500ms ease;
    }
    ::v-deep svg {
      @apply mx-auto;
      height: var(--perk-ico-height);
      path,
      circle {
        @apply stroke-current fill-current;
      }
    }
  }
  h6,
  p {
    color: alpha(var(--color-black-coral), 0.925);
  }
  h6 {
    @apply uppercase mb-2;
  }
  p {
    @apply w-4/5 mx-auto;
  }
}
</style>
