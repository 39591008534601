<template lang="pug">
form(@submit="checkForm" action="https://go.capitalvacations.com/l/659633/2021-02-04/2gnbd" method="post")
  fieldset.errors(v-if='errors.length')
    h6(v-if="errors.length === 1") Entry Error
    h6(v-if="errors.length > 1") Entry Errors
    p We aren't able to submit your entry for this month due to entry errors. Please review the errors below.
    ul
      li(v-for='error in errors') {{ error }}
  fieldset.name
    .input
      input(required v-model="name.first" autocomplete="given-name" id="name-first" name="name-first" type="text" :class="{ 'has-warning': attemptSubmit && missingNameFirst }")
      label(for="name-first") First Name
    .input
      input(required v-model="name.last" autocomplete="family-name" id="name-last" name="name-last" type="text" :class="{ 'has-warning': attemptSubmit && missingNameLast }")
      label(for="name-last") Last Name
  fieldset.address
    .input
      input(required v-model="address.line1" autocomplete="street-address" id="address-street" name="address-street" type="text")
      label(for="address-street") Address
    .input
      input(required v-model="address.city" autocomplete="address-level2" id="address-city" name="address-city" type="text")
      label(for="address-city") City
    .input
      select(required v-model="address.state" autocomplete="address-level1" id="address-state" name="address-state" type="text")
        option(selected='true' disabled='disabled') State
        option(v-model='selectedState' v-for='state in states' :value='state.ab' v-html="state.state")
      label(for="address-state") State
    .input
      input(required v-model="address.zip" autocomplete="postal-code" id="address-postal" name="address-postal" type="text" pattern="[0-9]{5}")
      label(for="address-postal") Zip
  fieldset.contact
    .input
      input(required v-model="email" autocomplete="email" id="contact-email" name="contact-email" type="email")
      label(for="contact-email") Email
    .input
      input(required v-model="phone" @input="formatPhone" autocomplete="tel" id="contact-phone" name="contact-phone" type="tel")
      label(for="contact-phone") Phone
  fieldset.enter
    input.agree(type='submit' :disabled='!isComplete' :title="submitTitle" @click="isAuthorized()" value='I agree, enter Sweepstakes')
    input(type='submit' :disabled='!isComplete' :title="submitTitle" value='I do not agree, enter Sweepstakes')
  fieldset.disclaimer
    p 
      | By clicking “I Agree”, I expressly authorize Capital Resorts Group, LLC, d/b/a Capital Vacations to contact me at the number and email I have entered, which I certify is my own number, including wireless (even if the number is a mobile phone number or is on their internal, the Federal, and/or any State “Do Not Call” List) regarding their products and services via mail, email, and/or telemarketing, (in which event they may deliver, or cause to be delivered, to me prerecorded messages and SMS text messages using an automatic telephone dialing system or an artificial or prerecorded voice, standard call and SMS text message rates may apply). I understand that I am not required to give my consent as a condition of purchasing any property, goods, or services to win. One entry per month, regardless of entry method. For more information, including odds and prize descriptions, see the full 
      router-link(:to="{ name: 'Legal'}" target="_blank" title="Full Details of Participation") details of participation
      | . Void where prohibited. 
  fieldset.identifiers.hidden.invisible.opacity-0
    .input
      input(v-model="location" id="location-identifier" name="location-identifier" type="text" readonly)
    .input
      input(v-model="authorized" id="contact-authorized" name="contact-authorized" type="text" readonly)
</template>

<script>
var blocked = [
  "Test",
  "Dummy",
  "Capital",
  "Vacation",
  "Vacations",
  "Suck",
  "Ripoff",
  "Timeshare"
];

export default {
  name: "EntryForm",
  components: {},
  props: {
    campaign: Object
  },
  data: function() {
    return {
      errors: [],
      attemptSubmit: false,
      location: this.campaign.id,
      name: {
        first: null,
        last: null
      },
      authorized: false,
      address: {
        line1: null,
        city: null,
        state: null,
        zip: null
      },
      email: null,
      phone: null,
      states: [
        {
          state: "Alabama",
          ab: "AL"
        },
        {
          state: "Alaska",
          ab: "AK"
        },
        {
          state: "Arizona",
          ab: "AZ"
        },
        {
          state: "Arkansas",
          ab: "AR"
        },
        {
          state: "California",
          ab: "CA"
        },
        {
          state: "Colorado",
          ab: "CO"
        },
        {
          state: "Connecticut",
          ab: "CT"
        },
        {
          state: "Delaware",
          ab: "DE"
        },
        {
          state: "District of Columbia",
          ab: "DC"
        },
        {
          state: "Florida",
          ab: "FL"
        },
        {
          state: "Georgia",
          ab: "GA"
        },
        {
          state: "Hawaii",
          ab: "HI"
        },
        {
          state: "Idaho",
          ab: "ID"
        },
        {
          state: "Illinois",
          ab: "IL"
        },
        {
          state: "Indiana",
          ab: "IN"
        },
        {
          state: "Iowa",
          ab: "IA"
        },
        {
          state: "Kansas",
          ab: "KS"
        },
        {
          state: "Kentucky",
          ab: "KY"
        },
        {
          state: "Louisiana",
          ab: "LA"
        },
        {
          state: "Maine",
          ab: "ME"
        },
        {
          state: "Maryland",
          ab: "MD"
        },
        {
          state: "Massachusetts",
          ab: "MA"
        },
        {
          state: "Michigan",
          ab: "MI"
        },
        {
          state: "Minnesota",
          ab: "MN"
        },
        {
          state: "Mississippi",
          ab: "MS"
        },
        {
          state: "Missouri",
          ab: "MO"
        },
        {
          state: "Montana",
          ab: "MT"
        },
        {
          state: "Nebraska",
          ab: "NE"
        },
        {
          state: "Nevada",
          ab: "NV"
        },
        {
          state: "New Hampshire",
          ab: "NH"
        },
        {
          state: "New Jersey",
          ab: "NJ"
        },
        {
          state: "New Mexico",
          ab: "NM"
        },
        {
          state: "New York",
          ab: "NY"
        },
        {
          state: "North Carolina",
          ab: "NC"
        },
        {
          state: "North Dakota",
          ab: "ND"
        },
        {
          state: "Ohio",
          ab: "OH"
        },
        {
          state: "Oklahoma",
          ab: "OK"
        },
        {
          state: "Orgeon",
          ab: "OR"
        },
        {
          state: "Pennsylvania",
          ab: "PA"
        },
        {
          state: "Rhode Island",
          ab: "RI"
        },
        {
          state: "South Carolina",
          ab: "SC"
        },
        {
          state: "South Dakota",
          ab: "SD"
        },
        {
          state: "Tennessee",
          ab: "TN"
        },
        {
          state: "Texas",
          ab: "TX"
        },
        {
          state: "Utah",
          ab: "UT"
        },
        {
          state: "Vermont",
          ab: "VT"
        },
        {
          state: "Virginia",
          ab: "VA"
        },
        {
          state: "Washington",
          ab: "WA"
        },
        {
          state: "West Virginia",
          ab: "WV"
        },
        {
          state: "Wisconsin",
          ab: "WI"
        },
        {
          state: "Wyoming",
          ab: "WY"
        }
      ]
    };
  },
  methods: {
    // Format Fields
    formatPhone() {
      var x = this.phone
        .replace(/\D/g, "")
        .match(/(\d{0,3})(\d{0,3})(\d{0,4})/);
      this.phone = !x[2]
        ? x[1]
        : "(" + x[1] + ") " + x[2] + (x[3] ? "-" + x[3] : "");
    },

    // Error Validation
    checkForm: function(e) {
      this.attemptSubmit = true;

      if (this.name.first && this.name.last && this.email) {
        return true;
      }

      this.errors = [];

      if (!this.name.first) {
        this.errors.push("Your first name is required.");
      }
      if (!this.name.last) {
        this.errors.push("Your last name is required.");
      }
      if (!this.phone) {
        this.errors.push("A valid US phone number is required.");
      }

      e.preventDefault();
      this.$gtag.event("Submit", { method: "Entry" });
    },

    // Change Authorized Status to "True" when main button clicked
    isAuthorized() {
      this.authorized = true;
    }
  },
  computed: {
    // Name First
    missingNameFirst: function() {
      return blocked.indexOf(this.name.first) >= 0;
    },
    // Name Lasts
    missingNameLast: function() {
      return blocked.indexOf(this.name.last) >= 0;
    },
    selectedState() {
      return this.states.find(i => i.value == this.selected);
    },
    // For button validation
    isComplete() {
      return (
        this.name.first &&
        this.name.last &&
        this.address &&
        this.email &&
        this.phone
      );
    },

    // Submit Titles
    submitTitle() {
      if (this.isComplete) {
        return "You're good to go! Submit your entry!";
      } else
        return "All fields are required. Ensure all input fields are complete to enable the submit buttons.";
    }
  }
};
</script>
