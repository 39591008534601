<template lang="pug">
.sweeps
  .form
    section.video-wrapper
      ftVideo(v-if="campaign.video" :video="campaign.video.mp4" :poster="campaign.video.still")
    section.form-wrapper
      //typeHeader(:text="campaign.tagline" subtitle="Explore")
      p Next Drawing In
      countdown
      entryForm(:campaign="campaign")
  .prize.container
    typeHeader(:text="campaign.tagline" subtitle="Explore")
    .perks
      perk(v-for='perk in perks' :ico="perk.ico" :title="perk.title" :copy="perk.copy")
  .winners.container
    typeHeader(text="Winners" subtitle="Getaway")
    calendar(:months="months")
</template>

<script>
import entryForm from "@/components/form";
import ftVideo from "@/components/video";
import perk from "@/components/perk";
import countdown from "@/components/countdown";

export default {
  name: "Sweeps",
  components: {
    entryForm,
    ftVideo,
    perk,
    countdown
  },
  props: {
    currentYear: Number,
    currentMonth: String,
    months: Array,
    campaign: Object
  },
  data: function() {
    return {
      perks: [
        {
          ico: "medal.svg",
          title: "12 Winners",
          copy:
            "We're sending winning families on a three-day, two-night vacation getaway to a preferred property of their choice!"
        },
        {
          ico: "clean.svg",
          title: "Prioritized Safety",
          copy:
            "Our preferred properties prioritize your family's health, implementing rigorous cleaning standards of all accommodation."
        },
        {
          ico: "cash.svg",
          title: "$250 Cash",
          copy:
            "Winning families will receive $250 cash to spend as they choose- whether it be fun, bills, or investing in cryptocurrency."
        }
      ]
    };
  }
};
</script>

<style scoped lang="scss">
@import "../assets/scss/color/_mixin";

@mixin aspect-ratio($width, $height) {
  @apply relative;
  &:before {
    @apply block w-full;
    content: "";
    padding-top: ($height / $width) * 100%;
  }
  > .video {
    @apply absolute inset-0;
  }
}

.form {
  @apply grid mb-6;
  min-height: calc(100vh - var(--nav-height));

  @screen maxSm {
    @apply grid-cols-1;
  }
  @screen minMd {
    @apply grid-cols-12;
  }

  section {
    .countdown {
      @apply mx-auto w-max;
      margin-bottom: var(--fs-gap);
      width: var(--frm-max-w);
      ::v-deep section {
        @apply p-3;
        p {
          &:nth-child(1) {
            @include gradient-a-text;
            font-size: var(--fs-xl);
            margin-bottom: calc(var(--fs-gap) / 6);
          }
          &:nth-child(2) {
            color: alpha(var(--color-black-coral), var(--fc-gray-alpha));
          }
        }
      }
    }
    &.video-wrapper {
      @apply col-span-5;
      .video {
        @apply w-full h-full sticky top-0;
        max-height: calc(100vh - var(--nav-height));
      }
    }
    &.form-wrapper {
      @apply col-span-7;
      > section {
        margin-bottom: 50px;
      }
      p {
        @apply text-center uppercase tracking-widest leading-none;
        max-width: var(--frm-max-w);
        font-size: var(--fs-xl);
        font-weight: var(--fw-bold);
        margin: var(--fs-gap) auto calc(var(--fs-gap) * 0.75);

        @include gradient-a-text;
      }
      form {
        @apply mx-auto;
      }
    }
  }
}

.container {
  // Cool Colors
  @apply relative;

  section {
    @screen maxSm {
      @apply col-span-1;
    }
    &:nth-child(2),
    &:nth-child(3) {
      // This is so the elements are clickable and above the color layer
      @apply z-10;
    }
    &:nth-child(2) {
      @apply relative;

      @screen minMd {
        @apply col-span-5;

        ::v-deep .video {
          @apply h-full;
          color: alpha(var(--color-white), 0.75);
        }
      }

      ::v-deep .video {
        z-index: -1;
      }
    }
    &:nth-child(3) {
      @screen minMd {
        @apply col-span-7;
      }
      .container {
        @apply w-3/4;
        h3 {
          @apply text-center mx-auto w-3/4;
        }
        form {
          margin: 5rem auto 0;
        }
      }
    }
  }
}

.prize {
  @apply mt-20;
  .perks {
    @apply grid pb-20;
    grid-gap: calc(var(--fs-gap) * 2);

    // Mobile
    @screen maxSm {
      @apply grid-cols-1;
    }
    // Desktop
    @screen minMd {
      @apply grid-cols-3;
    }

    // Hover FX
    &:hover {
      .perk {
        transition: all 500ms ease;
        &:hover {
          @apply opacity-100;
          transform: translate3d(0, -5px, 0);
        }
        &:not(:hover) {
          @apply opacity-25;
        }
      }
    }

    .perk {
      @apply col-span-1 text-center;
      transition: all 500ms ease;
    }
  }
}
</style>
