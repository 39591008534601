<template lang="pug">
nav(ref="header")
  .main.container
    section.hamburger(:class="{ active: mobileNavActive }" @click="mobileNavActive = !mobileNavActive")
      span
      span
      span
    section.logo
      router-link(:to="{ name: 'Home'}" title="Getaway Giveaway" v-html="require(`!html-loader!@/assets/img/logo.svg`)")
    section.items
      links
  transition(name='fade' mode="out-in")
    .mobile-nav(v-if="mobileNavActive")
      .container
        links
</template>

<script>
import links from "@/views/element/header/links.vue";

export default {
  name: "sweepHeader",
  components: {
    links
  },
  data: function() {
    return {
      mobileNavActive: false
    };
  },
  watch: {
    $route() {
      this.mobileNavActive = false;
    }
  }
};
</script>

<style scoped lang="scss">
@import "../../../assets/scss/color/_mixin";

nav {
  @apply relative w-full top-0 z-50 flex items-center;
  height: var(--nav-height);

  .main.container {
    // Both desktop and mobile
    @apply grid grid-flow-col items-center z-10;
    section {
      &.logo {
        ::v-deep svg {
          height: var(--nav-logo-height);
        }
      }
      &.items {
        ::v-deep ul {
          @apply grid grid-flow-col items-center;
          grid-gap: calc(var(--nav-grid-gap) * 1.5);
          li {
            @apply uppercase tracking-widest;
            font-family: var(--ff-header);
            font-size: var(--fs-xxs);
            a {
              &.router-link-exact-active {
                @include gradient-a-text;
              }
            }
          }
        }
      }
    }
  }

  @screen maxSm {
    // Mobile
    @apply sticky;
    background-color: alpha(var(--color-white), 0.9);
    backdrop-filter: blur(var(--nav-blur));

    .main.container {
      @apply relative justify-center z-50;
      section {
        &.hamburger {
          // Mobile Nav Icon
          @apply absolute grid rounded-full;
          $height: 36px;
          $width: $height;
          padding: calc(#{$height} * 0.2275);
          $size: 2px;

          width: $width;
          height: $height;
          left: 0;
          transition: all 500ms var(--ea);
          transform: rotate(-45deg);

          @include gradient-a;

          span {
            @apply w-full origin-center;
            height: $size;
            transition: all 500ms ease;
            background-color: alpha(var(--color-white), 1);
          }

          &.active {
            @apply content-center;
            span {
              &:nth-child(2) {
                @apply hidden invisible opacity-0 pointer-events-none;
              }
              &:nth-child(3) {
                transform: rotate(270deg) translateX(calc(#{$size} / 2));
              }
            }
          }

          &:not(.active) {
            @apply content-between;
            span {
              &:nth-child(1),
              &:nth-child(3) {
                transform: scale(var(--fc-gray-alpha), 1);
              }
            }
          }
        }
        &.items {
          @apply hidden invisible opacity-0 pointer-events-none;
        }
      }
    }

    .mobile-nav {
      @apply z-10 fixed w-full inset-0 flex items-center text-center;
      background-color: alpha(var(--color-gray), 1);
      backdrop-filter: blur(var(--nav-blur));
      height: calc(100vh - var(--nav-height));
      margin-top: var(--nav-height);
      ul {
        @apply grid uppercase;
        grid-gap: calc(var(--nav-grid-gap));
        font-family: var(--ff-header);

        // To center by offsetting the top bar
        margin-top: calc(var(--nav-height) * -1);
      }
    }
  }

  @screen minMd {
    // Desktop
    @apply bg-transparent justify-between;
    section {
      &.hamburger {
        @apply hidden invisible opacity-0 pointer-events-none;
      }
      &.logo,
      &.items {
        @apply w-max;
      }
      &.items {
        @apply grid items-center grid-flow-col justify-self-end;
        grid-gap: calc(var(--nav-grid-gap) * 1.5);
      }
    }
  }
}
</style>
