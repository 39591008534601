<template lang="pug">
.error
    .container
        h4.uppercase 404 - Page not Found
        p(v-if="campaign.address")
            | Toto, I've a feeling we're not in 
            del Kansas
            |  {{ campaign.address.state }} anymore.
        router-link(:to="{ name: 'Home'}").btn.-bg-gradient-a.-c-white Enter Sweepstakes
</template>

<script>
export default {
  name: "Error",
  props: {
    currentYear: Number,
    currentMonth: String,
    months: Array,
    campaign: Object
  },
  data: function() {
    return {};
  }
};
</script>

<style scoped lang="scss">
@import "../../assets/scss/color/_mixin";

.error {
  @apply relative;
  min-height: calc(100vh - var(--nav-height));
  .container {
    @apply absolute text-center;
    top: 50%;
    left: 50%;
    transform: translate(-50%, calc(-50% - calc(var(--nav-height) / 1.5)));
    .btn {
      @apply mt-12 mx-auto;
    }
  }
}
</style>
