<template lang="pug">
transition(name='slide-down' mode="out-in")
  .notification-bar.container#notifBar(v-if="scrollpx > 75")
    canvas#particles
    .container
      section
        p {{ currentMonth }} Countdown
      section 
        countdown
      section
        transition(name='fade' mode="out-in")
          router-link(:to="{ name: 'Home'}" v-if="$route.name != 'Home'").btn.-sm.-bg-white.-c-blue Enter to Win
</template>

<script>
import countdown from "@/components/countdown";

export default {
  name: "Action",
  data: function() {
    return {
      scrollpx: 0
    };
  },
  props: {
    currentMonth: String
  },
  components: {
    countdown
  },
  methods: {
    handleScroll() {
      this.scrollpx = window.scrollY;
    },
    fitToContainer() {
      var canvas = document.getElementById("particles");
      var parent = document.getElementById("notifBar");
      canvas.width = parent.offsetWidth;
      canvas.height = parent.offsetHeight;
    },
    start() {
      this.$confetti.start({
        particlesPerFrame: 1.5,
        defaultSize: 4,
        defaultDropRate: 2.5,
        windSpeedMax: 0,
        particles: [
          {
            type: "circle",
            colors: ["#fff"]
          },
          {
            type: "rect",
            colors: ["#fff"]
          }
        ],
        canvasElement: document.getElementById("particles")
      });
    },
    stop() {
      this.$confetti.stop();
    }
  },
  created() {
    window.addEventListener("scroll", this.handleScroll);
    //this.start();
    //this.fitToContainer();
  },
  destroyed() {
    window.removeEventListener("scroll", this.handleScroll);
  }
};
</script>

<style scoped lang="scss">
@import "../../assets/scss/color/_mixin";

.notification-bar {
  @apply fixed inset-x-0 z-50;
  height: var(--notif-height);
  background: linear-gradient(
    -45deg,
    alpha(var(--color-s-indigo), 0.9),
    alpha(var(--color-s-pink), 0.9),
    alpha(var(--color-s-blue), 0.9),
    alpha(var(--color-s-indigo), 0.9)
  );
  background-size: 400% 400%;
  backdrop-filter: blur(6px);
  animation: gradient 15s ease infinite;
  color: alpha(var(--color-white), 1);

  @apply px-6;
  border-radius: var(--fi-radius);

  @screen maxSm {
    bottom: var(--notif-offset-b-mobile);
  }
  @screen minMd {
    bottom: var(--notif-offset-b);
  }

  canvas {
    @apply absolute inset-0 opacity-25;
    mix-blend-mode: overlay;
    z-index: -1;
  }
  .container {
    @apply grid grid-cols-3 items-center h-full z-10;
    section {
      @apply col-span-1 overflow-hidden;
      p {
        @apply m-0 uppercase tracking-widest;
        font-family: var(--ff-header);
        font-size: var(--fs-xxs);
      }
      &:nth-child(3) {
        @apply flex justify-end;
      }
    }
  }
}

@keyframes gradient {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}
</style>
