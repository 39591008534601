<template lang="pug">
.video
  video(:poster='poster' ref="video" @canplay='updateStatus' @playing='updateStatus' @pause='updateStatus' autoplay loop muted playsinline)
    //source(src='https://s3-ap-northeast-1.amazonaws.com/daniemon/demos/Velocity-Mobile.mp4' type='video/mp4' media='all and (max-width:680px)')
    //source(src='https://s3-ap-northeast-1.amazonaws.com/daniemon/demos/Velocity-Mobile.webm' type='video/webm' media='all and (max-width:680px)')
    source(:src='video' type='video/mp4')
    //source(src='https://s3-ap-northeast-1.amazonaws.com/daniemon/demos/Velocity-SD.webm' type='video/webm')
    p Hmm. There's a problem playing this video. Please try using a different browser.
  transition(name='fade' mode="out-in")
    figure.still(v-if="paused")
      img(:src="poster")
  .control(:class="paused ? 'icon-play' : 'icon-pause'" :title="paused ? 'Play Media' : 'Pause Media'")
    transition(name='fade' mode="out-in")
      section(v-if='paused' @click='play' v-html="require(`!html-loader!@/assets/img/icons/video/play.svg`)")
      section(v-if='!paused' @click='pause' v-html="require(`!html-loader!@/assets/img/icons/video/pause.svg`)")
</template>

<script>
export default {
  name: "Video",
  props: {
    video: String,
    poster: String
  },
  data: function() {
    return {
      videoElement: this.$refs.video,
      paused: null
    };
  },
  methods: {
    updateStatus(event) {
      this.videoElement = event.target;
      this.paused = event.target.paused;
    },
    play() {
      this.videoElement.play();
    },
    pause() {
      this.videoElement.pause();
    }
  }
};
</script>

<style scoped lang="scss">
@import "../../assets/scss/color/_mixin";

.video {
  @apply relative;
  video,
  figure.still,
  .control {
    @apply absolute;
  }
  video,
  figure.still {
    @apply inset-0 w-full h-full object-cover object-center;
  }
  figure.still {
    img {
      @apply w-full h-full object-cover object-center;
    }
  }
  .control {
    right: 1rem;
    bottom: 1rem;
    border-radius: var(--fi-radius);
    background-color: alpha(var(--color-ghost), 0.25);
    backdrop-filter: blur(var(--nav-blur));
    color: alpha(var(--color-ghost), 1);

    // For centered
    //left: 50%;
    //transform: translate(-50%, 0);

    section {
      @apply select-none p-3;
      &:hover {
        @apply cursor-pointer;
      }
      ::v-deep svg {
        height: calc(var(--fs-base) * 1);
        @apply fill-current;
      }
    }
  }
}
</style>
